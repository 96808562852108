define("discourse/plugins/discourse-shared-edits/discourse/initializers/shared-edits-init", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse/models/composer"], function (_exports, _ajax, _ajaxError, _pluginApi, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SHARED_EDIT_ACTION = "sharedEdit";
  function replaceButton(buttons, find, replace) {
    const idx = buttons.indexOf(find);
    if (idx !== -1) {
      buttons[idx] = replace;
    }
  }
  function initWithApi(api) {
    _composer.SAVE_LABELS[SHARED_EDIT_ACTION] = "composer.save_edit";
    _composer.SAVE_ICONS[SHARED_EDIT_ACTION] = "pencil-alt";
    const currentUser = api.getCurrentUser();
    api.addPostAdminMenuButton(attrs => {
      if (!currentUser?.staff && currentUser?.trust_level < 4) {
        return;
      }
      return {
        icon: "far-edit",
        className: "admin-toggle-shared-edits",
        label: attrs.shared_edits_enabled ? "shared_edits.disable_shared_edits" : "shared_edits.enable_shared_edits",
        action: async post => {
          const url = `/shared_edits/p/${post.id}/${post.shared_edits_enabled ? "disable" : "enable"}.json`;
          try {
            await (0, _ajax.ajax)(url, {
              type: "PUT"
            });
            post.set("shared_edits_enabled", !post.shared_edits_enabled);
          } catch (e) {
            (0, _ajaxError.popupAjaxError)(e);
          }
        }
      };
    });
    api.includePostAttributes("shared_edits_enabled");
    api.addPostClassesCallback(attrs => {
      if (attrs.shared_edits_enabled && attrs.canEdit) {
        return ["shared-edits-post"];
      }
    });
    api.addPostMenuButton("sharedEdit", post => {
      if (!post.shared_edits_enabled || !post.canEdit) {
        return;
      }
      const result = {
        action: SHARED_EDIT_ACTION,
        icon: "far-edit",
        title: "shared_edits.button_title",
        className: "shared-edit create fade-out",
        position: "last"
      };
      if (!post.mobileView) {
        result.label = "shared_edits.edit";
      }
      return result;
    });
    api.removePostMenuButton("edit", attrs => {
      return attrs.shared_edits_enabled && attrs.canEdit;
    });
    api.removePostMenuButton("wiki-edit", attrs => {
      return attrs.shared_edits_enabled && attrs.canEdit;
    });
    api.reopenWidget("post-menu", {
      menuItems() {
        const result = this._super(...arguments);

        // wiki handles the reply button on its own. If not a wiki and is shared-edit
        // remove the label from the reply button.
        if (this.attrs.shared_edits_enabled && this.attrs.canEdit && !this.attrs.wiki) {
          replaceButton(result, "reply", "reply-small");
        }
        return result;
      },
      sharedEdit() {
        const post = this.findAncestorModel();
        this.appEvents.trigger("shared-edit-on-post", post);
      }
    });
    api.modifyClass("component:scrolling-post-stream", Superclass => class extends Superclass {
      sharedEdit() {
        this.appEvents.trigger("shared-edit-on-post");
      }
    });
    api.modifyClass("model:composer", Superclass => class extends Superclass {
      get creatingSharedEdit() {
        return this.get("action") === SHARED_EDIT_ACTION;
      }
      get editingPost() {
        return super.editingPost || this.creatingSharedEdit;
      }
    });
    api.modifyClass("component:composer-editor", Superclass => class extends Superclass {
      keyDown() {
        super.keyDown?.(...arguments);
        if (this.composer.action === SHARED_EDIT_ACTION) {
          this.composer.set("lastKeyPress", Date.now());
        }
      }
    });
    api.modifyClass("controller:topic", Superclass => class extends Superclass {
      init() {
        super.init(...arguments);
        this.appEvents.on("shared-edit-on-post", this, this._handleSharedEditOnPost);
      }
      willDestroy() {
        super.willDestroy(...arguments);
        this.appEvents.off("shared-edit-on-post", this, this._handleSharedEditOnPost);
      }
      _handleSharedEditOnPost(post) {
        const draftKey = post.get("topic.draft_key");
        const draftSequence = post.get("topic.draft_sequence");
        this.get("composer").open({
          post,
          action: SHARED_EDIT_ACTION,
          draftKey,
          draftSequence
        });
      }
    });
  }
  var _default = _exports.default = {
    name: "discourse-shared-edits",
    initialize: container => {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.shared_edits_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.6", initWithApi);
    }
  };
});